<template>
  <div>
    <div class="flex md12 xs12 chapter-row">
      <div class="row">
        <div class="chapter-index">
          <p class="flex flex-center">#{{ chapter.chapterIndex }}</p>
        </div>
        <div class="flex xs3 md8">
          <div class="grid__container flex-left">
            <b>Volume:</b> {{ chapter.volume }} | <b>Ch.</b>
            {{ chapter.chapter }}
            <p>{{ chapter.title | chapterTitle }}</p>
          </div>
          <va-badge outline color="warning">{{ !!chapter.mangaRelation ? chapter.mangaRelation.scraper.name :
            "Deleted" }}</va-badge>
          <va-badge v-if="chapter.approved" outline color="success">Approved</va-badge>
        </div>
        <div class="flex xs8 md3">
          <chapter-actions-buttons v-if="selectedChapterId === null" :chapterId="chapter.id"
            :approved="chapter.approved"
            :chapterLabel="`<b>Volume:</b> ${chapter.volume} | <b>Ch.</b> ${chapter.chapter}
                                                                                                              <p>Title: ${chapter.title}</p>`" />
        </div>
      </div>
    </div>
    <transition name="slide">
      <chapter-detail v-if="selectedChapterId === chapter.id" :chapterId="chapter.id" :hasApproved="chapter.approved" />
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import ChapterActionsButtons from './ChapterActionsButtons';
import ChapterDetail from './ChapterDetail';

export default {
  components: {
    ChapterActionsButtons,
    ChapterDetail,
  },
  computed: {
    ...mapGetters(['selectedChapterId']),
  },
  created() {
    if (this.$route.name === 'view-chapter-id') {
      this.setSelectedChapter({
        id: this.$route.params.chapter_id,
        mode: 'view',
      });
    } else if (this.$route.name === 'edit-chapter-id') {
      this.setSelectedChapter({
        id: this.$route.params.chapter_id,
        mode: 'edit',
      });
    }
  },
  filters: {
    chapterTitle: (value) => {
      if (!value || value === '') {
        return 'Title: NA';
      }
      return value;
    },
  },
  props: {
    chapter: {
      type: Object,
    },
    scraperName: {
      type: String,
    },
  },
  methods: {
    ...mapMutations(['setSelectedChapter']),
  },
};
</script>

<style lang="scss">
.chapter-row {
  background: #ffffff;
  border: 1px solid #e8e5e5;
  border-radius: 10px;
  padding: 4px;

  &:hover {
    border: 1px dashed #c2ac06;
  }
}

.chapter-index {
  display: flex;
  background-color: #0c9f67;
  width: 50px;
  border-radius: 10px 0 0 10px;
  justify-content: center;
  align-items: center;
}

.chapter-index > p {
  color: white;
  font-size: 18px;
  text-align: center;
}
</style>
